
import React from "react"
import ListmanagerProducttagPage from "../../components/negosyonow/listmanagers/producttag"


const productpickerfields = [
	{"label":"Last Update", "dbfield": "nnproduct_lastupdate", "type": "datetime", "filtertype": "datetime"},
	{"label":"Image", "dbfield": "nnproduct_image", "type": "image", "filtertype": "image"},
	{"label":"Seller SKU", "dbfield": "nnproduct_orgsku", "type": "text", "filtertype": "textbox"},
	{"label":"Name", "dbfield": "nnproduct_fullname", "type": "text", "filtertype": "textbox"},
	{"label":"Active", "dbfield": "nnproduct_active", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Price", "dbfield": "nnproduct_price", "type": "currency", "filtertype": "currency"},
];

const productpickerfilter = [
	{"field":"nnproduct_haspage", "operation": "=", "value": 1},
	{"field":"nnproduct_promoitem", "operation": "=", "value": 1}
];


const formFields = [
	[
		{"label":"Order", "field": "nnproducttag_order", "value": "", "input": "integer", "mode": "required"},
		{"label":"Product", "field": "nnproduct_fullname", "value": "", "input": "picker", "mode": "required", "pickerfields": productpickerfields, "pickerfilters": productpickerfilter},
		{"label":"Image", "field": "nnproduct_image", "value": "", "input": "image", "mode": "readonly"},
	]
];


const NewitemsPage = ({location}) => {
	return <ListmanagerProducttagPage
				location={location}
				allowadd={true}
				customFormFields={formFields}
				tagid={99}
			/>
}


export default NewitemsPage;
